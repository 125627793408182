import React, { Component } from 'react';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { rateTypeNameFinder } from 'helpers/rateTypeName';
import { formatCurrency } from 'helpers/dashboard';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { formatTimeTz, formatTableDateTz } from 'helpers/datetime';
import { isCancellationFee, isCancellationPayment } from 'helpers/dashboard';
import { SHOW_TOOLTIP_COLUMNS, createOverflowChecker } from 'helpers/dashboard';
import { UncontrolledTooltip } from 'reactstrap';

export default class FinancesJobItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: {},
    };
    this.columnRefs = {};
    SHOW_TOOLTIP_COLUMNS.financesJobs.forEach((column) => {
      this.columnRefs[column] = React.createRef();
    });
  }

  componentDidMount() {
    const checkOverflow = createOverflowChecker(
      'financesJobs',
      this.columnRefs,
      this.setState.bind(this)
    );

    this.resizeObserver = new ResizeObserver(checkOverflow);
    SHOW_TOOLTIP_COLUMNS.financesJobs.forEach((column) => {
      this.resizeObserver.observe(this.columnRefs[column].current);
    });
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
  }

  computeTotalPayment = () => {
    const { job } = this.props;
    const jobPayment = get(job, 'payment.eoTotalCharge', 0);
    const jobModificationPayment = get(job, 'jobModification.payment.eoTotalCharge', 0);
    return Number(jobPayment) + Number(jobModificationPayment);
  };

  formatUserName = () => {
    const { job } = this.props;
    const { user, cancelledAt } = job;
    let userName = user.name;

    if (!moment(cancelledAt).isValid()) return userName;

    if (isCancellationPayment(job)) userName = `${user.name} - Cancellation Payment`;
    if (isCancellationFee(job)) userName = `${user.name} - Cancellation Fee`;

    return userName;
  };

  render() {
    const { job } = this.props;
    const { shiftLocation } = job;
    const jobModification = get(job, 'jobModification', {});
    const tz = zipcode_to_timezone.lookup(shiftLocation.address.zipCode) || moment.tz.guess();

    const startTime = jobModification.startTime || job.startTime;
    const endTime = jobModification.endTime || job.endTime;
    const payRate = jobModification.payRate || job.payRate;
    const rateTypeId = jobModification.rateTypeId || job.rateTypeId;

    return (
      <div className="finances-job-item">
        <div className="col-md-2" id={`atName-${job.id}`} ref={this.columnRefs['atName']}>
          {this.formatUserName()}
          {this.state.isOverflowing.atName && (
            <UncontrolledTooltip target={`atName-${job.id}`}>
              {this.formatUserName()}
            </UncontrolledTooltip>
          )}
        </div>
        <div className="col-md-1" id={`shiftDate-${job.id}`} ref={this.columnRefs['shiftDate']}>
          {formatTableDateTz(startTime, tz)}
          {this.state.isOverflowing.shiftDate && (
            <UncontrolledTooltip target={`shiftDate-${job.id}`}>
              {formatTableDateTz(startTime, tz)}
            </UncontrolledTooltip>
          )}
        </div>
        <div className="col-md-2" id={`shiftTime-${job.id}`} ref={this.columnRefs['shiftTime']}>
          {`${formatTimeTz(startTime, tz)} - ${formatTimeTz(endTime, tz)}`}
          {this.state.isOverflowing.shiftTime && (
            <UncontrolledTooltip target={`shiftTime-${job.id}`}>
              {`${formatTimeTz(startTime, tz)} - ${formatTimeTz(endTime, tz)}`}
            </UncontrolledTooltip>
          )}
        </div>
        <div className="col-md-3" id={`location-${job.id}`} ref={this.columnRefs['location']}>
          {`${shiftLocation.address.city}, ${shiftLocation.address.state}`}
          {this.state.isOverflowing.location && (
            <UncontrolledTooltip target={`location-${job.id}`}>
              {`${shiftLocation.address.city}, ${shiftLocation.address.state}`}
            </UncontrolledTooltip>
          )}
        </div>
        <div className="col-md-1" id={`rate-${job.id}`} ref={this.columnRefs['rate']}>
          {`${formatCurrency({
            currency: payRate,
            dollarSign: true,
          })}
          /
          ${rateTypeNameFinder(this.props.rateTypes, rateTypeId)}`}{' '}
          {this.state.isOverflowing.rate && (
            <UncontrolledTooltip target={`rate-${job.id}`}>
              {`${formatCurrency({
                currency: payRate,
                dollarSign: true,
              })}
          /
          ${rateTypeNameFinder(this.props.rateTypes, rateTypeId)}`}
            </UncontrolledTooltip>
          )}
        </div>
        <div
          className="col-md-2"
          id={`totalPayment-${job.id}`}
          ref={this.columnRefs['totalPayment']}
        >
          {formatCurrency({
            currency: this.computeTotalPayment(),
            dollarSign: true,
          })}{' '}
          {this.state.isOverflowing.totalPayment && (
            <UncontrolledTooltip target={`totalPayment-${job.id}`}>
              {formatCurrency({
                currency: this.computeTotalPayment(),
                dollarSign: true,
              })}
            </UncontrolledTooltip>
          )}
        </div>
        <div className="col-md-1" id={`datePaid-${job.id}`} ref={this.columnRefs['datePaid']}>
          {formatTableDateTz(get(job, 'payment.paidAt'), tz)}
          {this.state.isOverflowing.datePaid && (
            <UncontrolledTooltip target={`datePaid-${job.id}`}>
              {formatTableDateTz(get(job, 'payment.paidAt'), tz)}
            </UncontrolledTooltip>
          )}
        </div>
      </div>
    );
  }
}

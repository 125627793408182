import { uniqBy, sortBy } from 'lodash';
import EventLocationSerializer from '../EventLocationSerializer';
import { ReportToContact, newLocation } from 'redux/ducks/Post/Locations';
import DetailsSerializer from './DetailsSerializer';
import ReportToContactSerializer from '../ReportToContactSerializer';

export default class LocationSerializer {
  static deserialize(event) {
    const sortedShifts = sortBy(event.shifts, ['id']);

    const locations = sortedShifts.flatMap((shift) => ({
      ...shift.event_location,
      instructions: shift.instructions,
      countValue: shift.no_fields || shift.no_courts || shift.no_mats || shift.no_rinks,
      countType: 'noFields',
      supplies: shift.details,
      scheduleType: 'one-time',
      payRate: shift.details?.location_pay_rate || shift.pay_rate || 0,
      rateTypeId: shift.details?.location_rate_type_id || shift.rate_type_id || '0',
      reportToContacts: shift.shift_contacts,
    }));

    const eventLocations = uniqBy(locations, 'id').map((location, idx) => ({
      ...EventLocationSerializer.deserialize(location),
      instructions: location.instructions,
      countValue: location.countValue,
      countType: location.countType,
      errorMessages: {},
      supplies: DetailsSerializer.deserialize(location.supplies),
      scheduleType: location.scheduleType,
      payRate: location.payRate || 0,
      rateTypeId: location.rateTypeId || '0',
      idx,
      reportToContacts: location.reportToContacts.map((contact) =>
        ReportToContactSerializer.deserialize(contact)
      ),
      existingDocuments: event.location_documents?.filter(
        (document) => document.location_id === `${location.id}`
      ),
      documents: [],
    }));

    const newLocations = Array.from(
      { length: event.locations_no - eventLocations.length },
      (_, idx) => newLocation(idx + eventLocations.length)
    );

    return [...eventLocations, ...newLocations];
  }
  static deserializeCamelCase(event) {
    const sortedShifts = sortBy(event.shifts, ['id']);
    const locations = sortedShifts.map((shift) => ({
      ...shift.eventLocation,
      instructions: shift.instructions,
      countValue: shift.countValue,
      countType: shift.countType,
      supplies: shift.details,
      payRate: shift.details?.locationPayRate || shift.payRate,
      rateTypeId: shift.details?.locationRateTypeId || shift.rateTypeId,
      reportToContacts: shift.reportToContacts,
      documents: [],
    }));

    return uniqBy(locations, 'id').map((location, idx) => ({
      ...location,
      scheduleType: 'one-time',
      reportToContacts: !location.reportToContacts.length ? [new ReportToContact()] : location.reportToContacts,
      errorMessages: {},
      existingDocuments: event.location_documents?.filter(
        (document) => document.location_id === `${location.id}`
      ),
      documents: [],
      idx,
    }));
  }
}
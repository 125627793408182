import { mapValues, keyBy, assign } from "lodash";

// Imported Actions
// ========================================================
import {
  UPDATE_JOB_SUCCESS,
  DECLINE_JOB_MODIFICATION_SUCCESS,
  UPDATE_JOB_V2_SUCCESS,
} from "redux/ducks/Job";
import { CREATE_EAP_SUCCESS } from "redux/ducks/CreateEvent/EAP/actions";
import { APPROVE_JOB_MODIFICATION_SUCCESS } from "../Job";

// Actions
// ========================================================
const constForName = (name: string) => `go4ellis/Event/${name}`;
export const APPLY_DRAFT_FILTERS = constForName('APPLY_DRAFT_FILTERS');
export const APPLY_PAST_FILTERS = constForName('APPLY_PAST_FILTERS');
export const APPLY_UPCOMING_FILTERS = constForName('APPLY_UPCOMING_FILTERS');

export const DELETE_EVENT = constForName('DELETE_EVENT');
export const DELETE_EVENT_SUCCESS = constForName('DELETE_EVENT_SUCCESS');
export const DELETE_EVENT_ERROR = constForName('DELETE_EVENT_ERROR');

export const GET_DASHBOARD_EVENTS = constForName('GET_DASHBOARD_EVENTS');
export const GET_DASHBOARD_EVENTS_SUCCESS = constForName('GET_DASHBOARD_EVENTS_SUCCESS');
export const GET_DASHBOARD_EVENTS_ERROR = constForName('GET_DASHBOARD_EVENTS_ERROR');

export const GET_DRAFT_EVENTS = constForName('GET_DRAFT_EVENTS');
export const GET_DRAFT_EVENTS_SUCCESS = constForName('GET_DRAFT_EVENTS_SUCCESS');
export const GET_DRAFT_EVENTS_ERROR = constForName('GET_DRAFT_EVENTS_ERROR');
export const CHANGE_DRAFT_EVENTS_PAGE = constForName('CHANGE_DRAFT_EVENTS_PAGE');

export const GET_PAST_EVENTS = constForName('GET_PAST_EVENTS');
export const GET_PAST_EVENTS_SUCCESS = constForName('GET_PAST_EVENTS_SUCCESS');
export const GET_PAST_EVENTS_ERROR = constForName('GET_PAST_EVENTS_ERROR');
export const CHANGE_PAST_EVENTS_PAGE = constForName('CHANGE_PAST_EVENTS_PAGE');

export const GET_UPCOMING_EVENTS = constForName('GET_UPCOMING_EVENTS');
export const GET_UPCOMING_EVENTS_SUCCESS = constForName('GET_UPCOMING_EVENTS_SUCCESS');
export const GET_UPCOMING_EVENTS_ERROR = constForName('GET_UPCOMING_EVENTS_ERROR');
export const GET_UPCOMING_SHIFTS = constForName('GET_UPCOMING_SHIFTS');
export const GET_UPCOMING_SHIFTS_SUCCESS = constForName('GET_UPCOMING_SHIFTS_SUCCESS');
export const GET_UPCOMING_SHIFTS_ERROR = constForName('GET_UPCOMING_SHIFTS_ERROR');
export const CHANGE_UPCOMING_EVENTS_PAGE = constForName('CHANGE_UPCOMING_EVENTS_PAGE');
export const CHANGE_UPCOMING_SHIFTS_PAGE = constForName('CHANGE_UPCOMING_SHIFTS_PAGE');

export const RELOAD_DASHBOARD_EVENTS_SUCCESS = constForName('RELOAD_DASHBOARD_EVENTS_SUCCESS');
export const RELOAD_DRAFT_EVENTS_SUCCESS = constForName('RELOAD_DRAFT_EVENTS_SUCCESS');
export const RELOAD_PAST_EVENTS_SUCCESS = constForName('RELOAD_PAST_EVENTS_SUCCESS');
export const RELOAD_UPCOMING_EVENTS_SUCCESS = constForName('RELOAD_UPCOMING_EVENTS_SUCCESS');

export const RESET_DRAFT_FILTERS = constForName('RESET_DRAFT_FILTERS');
export const RESET_UPCOMING_EVENTS = constForName('RESET_UPCOMING_EVENTS');
export const RESET_PAST_FILTERS = constForName('RESET_PAST_FILTERS');
export const RESET_UPCOMING_FILTERS = constForName('RESET_UPCOMING_FILTERS');

export const UPDATE_DRAFT_FILTERS = constForName('UPDATE_DRAFT_FILTERS');
export const UPDATE_PAST_FILTERS = constForName('UPDATE_PAST_FILTERS');
export const UPDATE_UPCOMING_FILTERS = constForName('UPDATE_UPCOMING_FILTERS');

export const EXPORT_EVENTS = constForName('EXPORT_EVENTS');
export const EXPORT_EVENTS_SUCCESS = constForName('EXPORT_EVENTS_SUCCESS');
export const EXPORT_EVENTS_ERROR = constForName('EXPORT_EVENTS_ERROR');

export const GET_EVENT_PAGE = constForName('GET_EVENT_PAGE');
export const GET_EVENT_PAGE_SUCCESS = constForName('GET_EVENT_PAGE_SUCCESS');
export const GET_EVENT_PAGE_ERROR = constForName('GET_EVENT_PAGE_ERROR');

export const RESET_PAGINATION = constForName('RESET_PAGINATION');

// Reducer Functions
// ========================================================
function approveJobModificationSuccessReducer(state, { jobId, currentState }) {
  const upcoming = mapValues(state.events.upcoming, (event) => {
    return {
      ...event,
      shifts: {
        ...event.shifts,
        byId: mapValues(event.shifts.byId, (shift) => {
          if (shift.jobs.ids.includes(jobId)) {
            const jobModification = assign(
              {},
              shift.jobs.byId[jobId].jobModification,
              { currentState }
            );
            const newJob = {
              ...shift.jobs.byId[jobId],
              jobModification,
            };
            shift.jobs.byId[jobId] = { ...newJob };
          }
          return { ...shift };
        }),
      },
    };
  });

  return {
    ...state,
    events: { ...state.events, upcoming },
  };
}

function createEAPSuccessReducer(state, newEap) {
  const locationId = newEap.eventLocation.id;
  const upcoming = mapValues(state.events.upcoming, (event) => {
    return {
      ...event,
      shifts: {
        ...event.shifts,
        byId: mapValues(event.shifts.byId, (shift) => {
          if (shift.eventLocation.id === locationId) {
            return {
              ...shift,
              emergencyActionPlanId: newEap.id,
            };
          } else {
            return shift;
          }
        }),
      },
    };
  });

  return {
    ...state,
    events: { ...state.events, upcoming },
  };
}

function declineJobModificationSuccessReducer(state, { jobId }) {
  const upcoming = mapValues(state.events.upcoming, (event) => {
    return {
      ...event,
      shifts: {
        ...event.shifts,
        byId: mapValues(event.shifts.byId, (shift) => {
          if (shift.jobs.ids.includes(jobId)) {
            assign(shift.jobs.byId[jobId], { jobModification: null });
          }
          return shift;
        }),
      },
    };
  });

  return {
    ...state,
    events: { ...state.events, upcoming },
  };
}

function deleteEventReducer(state, event) {
  const key = event.isDraft ? "draft" : "upcoming";
  const newEvents = Object.keys(state.events[key]).reduce((acc, a) => {
    if (parseInt(a) !== event.id) {
      acc[a] = state.events[key][a];
    }
    return acc;
  }, {});
  return {
    ...state,
    events: {
      ...state.events,
      [key]: newEvents,
    },
  };
}

function getEventsErrorReducer(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function getEventsReducer(state, action) {
  // Middleware: getEventsEpic
  return {
    ...state,
    error: null,
  };
}

function getEventsSuccessReducer(state, newEvents, key) {
  return {
    ...state,
    events: {
      ...state.events,
      [key]: keyBy(newEvents.events, "id"),
      [key + "Pagination"]: {
        total: newEvents.total,
        per_page: newEvents.per_page,
        total_pages: newEvents.total_pages,
        current_page: newEvents.current_page,
      },
    },
    loaded: { ...state.loaded, [key]: true },
  };
}

function getShiftsSuccessReducer(state, newEvents, key) {
  return {
    ...state,
    eventsGroupedByShifts: {
      ...state.eventsGroupedByShifts,
      [key]: keyBy(newEvents.events, ({ shifts: { ids } }) => ids[0]),
      [key + "Pagination"]: {
        total: newEvents.total,
        per_page: newEvents.per_page,
        total_pages: newEvents.total_pages,
        current_page: newEvents.current_page,
      },
    },
    loaded: { ...state.loaded, [key + 'Shifts']: true },
  };
}

function reloadEventsReducer(state, newEvents, key) {
  const events = { ...state.events };
  events[key] = keyBy(newEvents.events, "id");
  events[key + "Pagination"] = {
    total: newEvents.total,
    per_page: newEvents.per_page,
    total_pages: newEvents.total_pages,
    current_page: newEvents.current_page,
  };

  return {
    ...state,
    events,
  };
}

function changeEventsPageReducer(state, payload, key) {
  const events = { ...state.events };
  events[key + "Pagination"].current_page = payload.page;

  return {
    ...state,
    events,
  };
}

function changeShiftsPageReducer(state, payload, key) {
  const eventsGroupedByShifts = { ...state.eventsGroupedByShifts };
  eventsGroupedByShifts[key + "Pagination"].current_page = payload.page;

  return {
    ...state,
    eventsGroupedByShifts,
  };
}

function resetDraftFiltersReducer(state, filters) {
  return {
    ...state,
    events: {
      ...state.events,
      pastPagination: {
        total: 0,
        per_page: 0,
        total_pages: 0,
        current_page: 0,
      },
      draftPagination: {
        total: 0,
        per_page: 0,
        total_pages: 0,
        current_page: 0,
      },
      upcomingPagination: {
        total: 0,
        per_page: 0,
        total_pages: 0,
        current_page: 0,
      },
    },
    filters: {
      ...state.filters,
      draft: {
        title: "",
        eventOperator: "",
        eventProfession: "",
        eventSetting: "",
        settingDetail: "",
        startDate: "",
        endDate: "",
        sport: "",
        sort: { date: 'asc' },
        draft: true,
      },
    },
  };
}

function resetUpcomingFiltersReducer(state, filters) {
  return {
    ...state,
    events: {
      ...state.events,
    },
    filters: {
      ...state.filters,
      upcoming: {
        title: "",
        eventProfession: "",
        eventSetting: "",
        settingDetail: "",
        startDate: "",
        endDate: "",
        sport: "",
        sort: { date: 'asc' },
        upcoming: true,
      },
    },
  };
}

function resetPastFiltersReducer(state, filters) {
  return {
    ...state,
    events: {
      ...state.events,
    },
    filters: {
      ...state.filters,
      past: {
        healthcareProfessional: "",
        title: "",
        eventProfession: "",
        eventSetting: "",
        settingDetail: "",
        startDate: "",
        endDate: "",
        sport: "",
        eventCode: "",
        stripePaidAt: "",
        eoTotalCharge: "",
        atTotalCharge: "",
        past: true,
        sort: { date: 'desc' },
        search: ''
      },
    },
  };
}

function updateFilterReducer(state, payload, key) {
  const filters = { ...state.filters };
  const filter = { ...filters[key] };

  filter[payload.filter] = payload.value;
  filters[key] = filter;

  return {
    ...state,
    filters,
  };
}

function updateJobSuccessReducer(state, action) {
  const job = action.payload;
  const upcoming = mapValues(state.events.upcoming, (event) => {
    return {
      ...event,
      shifts: {
        ...event.shifts,
        byId: mapValues(event.shifts.byId, (shift) => {
          if (shift.jobs.ids.includes(job.id)) {
            shift.jobs.byId[job.id] = { ...job };
          }
          return { ...shift };
        }),
      },
    };
  });

  return {
    ...state,
    events: { ...state.events, upcoming },
  };
}

// Action Creators
// ========================================================
export function applyDraftFilters() {
  return { type: APPLY_DRAFT_FILTERS };
}

export function applyPastFilters() {
  return { type: APPLY_PAST_FILTERS };
}

export function applyUpcomingFilters() {
  return { type: APPLY_UPCOMING_FILTERS };
}

export function deleteEvent(event) {
  return { type: DELETE_EVENT, payload: event };
}

export function getDashboardEvents() {
  return { type: GET_DASHBOARD_EVENTS };
}

export function getDraftEvents(params) {
  return { type: GET_DRAFT_EVENTS, payload: { draft: true, ...params } };
}

export function getPastEvents(params) {
  return { type: GET_PAST_EVENTS, payload: { past: true, ...params } };
}

export function getUpcomingEvents(params = {}) {
  return { type: GET_UPCOMING_EVENTS, payload: { upcoming: true, ...params } };
}

export function getUpcomingShifts(params = {}) {
  return { type: GET_UPCOMING_SHIFTS, payload: { upcoming: true, ...params } };
}

export function changeDraftEventsPage(params) {
  return { type: CHANGE_DRAFT_EVENTS_PAGE, payload: { page: params } };
}

export function changePastEventsPage(params) {
  return { type: CHANGE_PAST_EVENTS_PAGE, payload: { page: params } };
}

export function changeUpcomingEventsPage(params) {
  return { type: CHANGE_UPCOMING_EVENTS_PAGE, payload: { page: params } };
}

export function changeUpcomingShiftsPage(params) {
  return { type: CHANGE_UPCOMING_SHIFTS_PAGE, payload: { page: params } };
}

export function resetDraftFilters(filters) {
  return {
    type: RESET_DRAFT_FILTERS,
    payload: filters,
  };
}

export function resetPastFilters(filters) {
  return {
    type: RESET_PAST_FILTERS,
    payload: filters,
  };
}

export function resetUpcomingFilters(filters) {
  return {
    type: RESET_UPCOMING_FILTERS,
    payload: filters,
  };
}

export function updateDraftFilters(filter, value) {
  return {
    type: UPDATE_DRAFT_FILTERS,
    payload: {
      filter,
      value,
    },
  };
}

export function updatePastFilters(filter, value) {
  return {
    type: UPDATE_PAST_FILTERS,
    payload: {
      filter: filter,
      value: value,
    },
  };
}

export function updateUpcomingFilters(filter, value) {
  return {
    type: UPDATE_UPCOMING_FILTERS,
    payload: {
      filter: filter,
      value: value,
    },
  };
}

export function exportEvents() {
  return { type: EXPORT_EVENTS, payload: {} };
}

export function resetUpcomingEvents() {
  return { type: RESET_UPCOMING_EVENTS };
}

export function resetPagination() {
  return { type: RESET_PAGINATION };
}

export function getEventIndexPage(eventId) {
  return { type: GET_EVENT_PAGE, payload: { eventId } };
}

export function resetPaginationReducer(state) {
  return {
    ...state,
    events: {
      ...state.events,
      pastPagination: {
        current_page: 1,
      },
      draftPagination: {
        current_page: 1,
      },
      upcomingPagination: {
        current_page: 1,
      },
    },
  };
}

// Reducer
// ========================================================
const DEFAULT_STATE = {
  loaded: {
    draft: false,
    past: false,
    upcoming: false,
    upcomingShifts: false,
  },
  events: {
    draft: {},
    past: {},
    upcoming: {},
    pastPagination: {
      total: 0,
      per_page: 0,
      total_pages: 0,
      current_page: 1,
    },
    draftPagination: {
      total: 0,
      per_page: 0,
      total_pages: 0,
      current_page: 1,
    },
    upcomingPagination: {
      total: 0,
      per_page: 0,
      total_pages: 0,
      current_page: 1,
    },
  },
  eventsGroupedByShifts: {
    draft: {},
    past: {},
    upcoming: {},
  },
  filters: {
    draft: {
      title: "",
      eventOperator: "",
      eventProfession: "",
      eventSetting: "",
      settingDetail: "",
      startDate: "",
      endDate: "",
      sport: "",
      draft: true,
      sort: { created_at: 'desc' }
    },
    past: {
      healthcareProfessional: "",
      title: "",
      eventProfession: "",
      eventSetting: "",
      settingDetail: "",
      startDate: "",
      endDate: "",
      sport: "",
      eventCode: "",
      stripePaidAt: "",
      eoTotalCharge: "",
      atTotalCharge: "",
      past: true,
      sort: { date: 'desc' },
      search: ''
    },
    upcoming: {
      title: "",
      eventProfession: "",
      eventSetting: "",
      settingDetail: "",
      startDate: "",
      endDate: "",
      sport: "",
      upcoming: true,
      sort: { date: 'asc' },
    },
  },
};

export default function reducer(state = DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case APPROVE_JOB_MODIFICATION_SUCCESS:
      return approveJobModificationSuccessReducer(state, action.payload);
    case CREATE_EAP_SUCCESS:
      return createEAPSuccessReducer(state, action.payload);
    case DECLINE_JOB_MODIFICATION_SUCCESS:
      return declineJobModificationSuccessReducer(state, action.payload);
    case DELETE_EVENT:
      return deleteEventReducer(state, action.payload);
    case APPLY_UPCOMING_FILTERS:
    case APPLY_PAST_FILTERS:
    case APPLY_DRAFT_FILTERS:
    case RESET_PAGINATION:
      return resetPaginationReducer(state);
    case GET_DRAFT_EVENTS:
    case GET_PAST_EVENTS:
    case GET_UPCOMING_EVENTS:
    case GET_UPCOMING_SHIFTS:
      return getEventsReducer(state, action.payload);
    case GET_DASHBOARD_EVENTS_SUCCESS:
      return getEventsSuccessReducer(state, action.payload, "dashboard");
    case GET_DRAFT_EVENTS_SUCCESS:
      return getEventsSuccessReducer(state, action.payload, "draft");
    case GET_PAST_EVENTS_SUCCESS:
      return getEventsSuccessReducer(state, action.payload, "past");
    case GET_UPCOMING_EVENTS_SUCCESS:
      return getEventsSuccessReducer(state, action.payload, "upcoming");
    case GET_UPCOMING_SHIFTS_SUCCESS:
      return getShiftsSuccessReducer(state, action.payload, "upcoming");
    case GET_DRAFT_EVENTS_ERROR:
    case GET_PAST_EVENTS_ERROR:
    case GET_UPCOMING_EVENTS_ERROR:
    case GET_UPCOMING_SHIFTS_ERROR:
      return getEventsErrorReducer(state, action);
    case CHANGE_DRAFT_EVENTS_PAGE:
      return changeEventsPageReducer(state, action.payload, "draft");
    case CHANGE_PAST_EVENTS_PAGE:
      return changeEventsPageReducer(state, action.payload, "past");
    case CHANGE_UPCOMING_EVENTS_PAGE:
      return changeEventsPageReducer(state, action.payload, "upcoming");
    case CHANGE_UPCOMING_SHIFTS_PAGE:
      return changeShiftsPageReducer(state, action.payload, "upcoming");
    case RELOAD_DASHBOARD_EVENTS_SUCCESS:
      return reloadEventsReducer(state, action.payload, "dashboard");
    case RELOAD_DRAFT_EVENTS_SUCCESS:
      return reloadEventsReducer(state, action.payload, "draft");
    case RELOAD_PAST_EVENTS_SUCCESS:
      return reloadEventsReducer(state, action.payload, "past");
    case RELOAD_UPCOMING_EVENTS_SUCCESS:
      return reloadEventsReducer(state, action.payload, "upcoming");
    case RESET_DRAFT_FILTERS:
      return resetDraftFiltersReducer(state, action.payload);
    case RESET_PAST_FILTERS:
      return resetPastFiltersReducer(state, action.payload);
    case RESET_UPCOMING_FILTERS:
      return resetUpcomingFiltersReducer(state, action.payload);
    case UPDATE_DRAFT_FILTERS:
      return updateFilterReducer(state, action.payload, "draft");
    case UPDATE_PAST_FILTERS:
      return updateFilterReducer(state, action.payload, "past");
    case UPDATE_UPCOMING_FILTERS:
      return updateFilterReducer(state, action.payload, "upcoming");
    case UPDATE_JOB_SUCCESS:
    case UPDATE_JOB_V2_SUCCESS:
      return updateJobSuccessReducer(state, action);
    case RESET_UPCOMING_EVENTS:
      return {
        ...state,
        loaded: { ...state.loaded, upcoming: false },
        events: { ...state.events, upcoming: {} },
        eventsGroupedByShifts: { ...state.events, upcoming: {} },
      };
    default:
      return state;
  }
}

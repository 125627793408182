import { getSettingName } from './enums';
import { isEmpty, omit, sum, get, uniqBy, map, cloneDeepWith, groupBy, sortBy } from 'lodash';
import { getEstimatedFee } from './getEstimatedFee';
import zipcode_to_timezone from 'zipcode-to-timezone';
import moment from 'moment-timezone';
import { MILITARY_FORMAT } from './datetime';

export const AVAILABLE_EQUIPMENTS = [
  'AUTOMATED EXTERNAL DEFIBRILLATOR (AED)',
  'Cold Water Immersion Tub',
  'Coolers',
  'Crutches',
  'Golf Cart',
  'Ice',
  'Ice Bags',
  'Medical Tent',
  'Spine Board',
  'Splint Bag',
  'Supplemental Oxygen',
  'Tape',
  'Treatment Table',
  'Walkie Talkie',
  'Water',
  'Wound Care',
];

export const profileValid = (profile, user) => {
  const settingName = getSettingName(profile.eventSettingId);

  const required = {
    title: true,
    sportId: ['School', 'Sports'].includes(settingName),
    genderId: ['School', 'Sports'].includes(settingName),
    ageBracketIds: ['School', 'Sports'].includes(settingName),
    jobDescriptionIds: false,
    participantNumbersId: true,
    eventSettingId: true,
    settingDetailId: true,
    eventCode: !!user?.jobCodeRequired
  };

  return Object.keys(required)
    .filter((key) => required[key])
    .every((key) => !isEmpty(profile[key]?.toString()));
};

export const locationValid = (location, profile) => {
  const { errorMessages } = location;
  const settingName = getSettingName(profile.eventSettingId);
  const validationKeys =
    settingName === 'Industrial'
      ? Object.keys(errorMessages).filter((key) => key !== 'countValue')
      : Object.keys(errorMessages);

  return validationKeys.every((key) => !errorMessages[key]);
};

export const scheduleValid = (location, shifts, editMode) => {
  if (!shifts.length) return false;

  const tz = getTimezone(location);

  if (!editMode) {
    return shifts.every((s) => {
      return (
        !s.errorMessages['startDate'] &&
        !s.errorMessages['start'] &&
        !s.errorMessages['end'] &&
        !s.errorMessages['capacity']
      );
    });
  }

  const items = shifts.filter((s) =>
    !s.cancelled && moment(s.endTime, MILITARY_FORMAT).isValid()
      ? isEndTimeAfterNow(s.endTime, tz)
      : true
  );
  if (items.length === 0) return true;

  return items.every(
    (s) => !s.errorMessages['start'] && !s.errorMessages['end'] && !s.errorMessages['capacity']
  );
};

export const rateValid = (rate) => {
  if (!rate) return false;

  return (
    !Number.isNaN(parseInt(rate.payRate)) &&
    !Number.isNaN(parseInt(rate.rateTypeId)) &&
    100_000 - Number(rate.payRate) > 0 &&
    Number(rate.payRate) > 0
  );
};

export const completedEAP = (eap, shifts) => {
  if (!shifts.length) return false;

  const eapLocationList =
    eap.forms?.reduce((acc, form) => {
      if (form.id) acc.push(get(form, 'eventLocation.id'));
      return acc;
    }, []) || [];
  return shifts.every(
    (shift) => !!shift.emergencyActionPlanId || eapLocationList.includes(shift.locationId)
  );
};

export const eapValid = (eap, location) => {
  if (!eap.forms.length) return false;

  const formIndex = eap.forms.findIndex((form) => location.id === form.eventLocation.id);

  if (formIndex === -1) return false;

  const form = eap.forms[formIndex];

  const { uploadedPlanPreview, generateOrUpload, id } = form;

  return generateOrUpload === 'generate' || uploadedPlanPreview || id;
};

export const requiredCredentialsValid = (l) => Array.isArray(l.supplies?.requiredCredentials);

export const postValid = (post) => {
  const { shifts, details, locations, profile } = post;
  const shiftLocationIds = shifts.map((shift) => shift.locationId);
  const locationIds = locations.map((location) => location.id);

  return (
    profileValid(profile) &&
    shifts.every(rateValid) &&
    shifts.length > 0 &&
    Number(details.locationsNo) >= 1 &&
    shiftLocationIds.every((id) => locationIds.includes(id))
  );
};

export const completeness = (item) => {
  const shifts = Object.values(get(item, 'shifts.byId', {}));
  const shiftsGroupedByLocation = groupBy(shifts, 'eventLocation.id');
  const locations = map(shiftsGroupedByLocation, '[0].eventLocation');
  const hasRequiredCredentials = shifts.every((shift) => shift.details?.requiredCredentials);
  return (
    profileValid(item) &&
    shifts.every(rateValid) &&
    hasRequiredCredentials &&
    shifts.length &&
    item.locationsNo >= 1 &&
    locations.every((location) => location.id) &&
    shifts.every((shift) => shift.emergencyActionPlanId) &&
    shifts.every((s) => isEndTimeAfterNow(s.endTime, getTimezone(s.eventLocation)))
  );
};

export const scheduleTypes = {
  ONETIME: 'one-time',
  RECURRING: 'recurring',
};

export const isId = (id) => {
  if (Array.isArray(id) && id.length > 0) {
    return id.every((i) => isId(i));
  }

  return Number.isInteger(Number(parseInt(id)));
};

export const DATE_PICKER_OPTIONS = {
  enableTime: false,
  dateFormat: 'm-d-Y',
  altFormat: 'm/d/y',
  altInput: false,
  disableMobile: true,
};

export const TIME_PICKER_OPTIONS = {
  enableTime: true,
  noCalendar: true,
  dateFormat: 'h:i K',
  altInput: false,
  disableMobile: true,
};

export const getDayName = (shift) => {
  const start = moment(shift.startTime, MILITARY_FORMAT);
  const end = moment(shift.endTime, MILITARY_FORMAT);
  if (shift.isMultiDay && !start.isSame(end, 'date'))
    return `${start.format('ddd')} - ${end.format('ddd')}`;

  return start.format('dddd');
};

export const shiftDuration = (shift) => {
  const start = moment(shift.startTime, MILITARY_FORMAT);
  const end = moment(shift.endTime, MILITARY_FORMAT);

  if (!start.isValid() || !end.isValid()) return 0;

  if (shift.isMultiDay)
    return (moment.duration(end.diff(start)).asHours() % 24).toFixed(1).replace(/\.0$/, '');

  return moment.duration(end.diff(start)).asHours().toFixed(1).replace(/\.0$/, '');
};

export const eventDuration = (event) =>
  event.shifts
    .reduce((duration, shift) => Number(duration) + Number(shiftDuration(shift)), 0)
    .toFixed(1)
    .replace(/\.0$/, '');

export const shiftRate = (shift) => {
  if (!shift) return 0;

  const multiplier = `${shift.rateTypeId}` === '1' ? 1 : shiftDuration(shift);
  return shift.payRate * multiplier * shift.capacity;
};

export const isAmericanExpress = (payment) => {
  return (
    payment &&
    payment.source &&
    payment.source.object === 'card' &&
    payment.source.brand === 'American Express'
  );
};

export const serviceFee = ({ shifts, payment, currentUser }) => {
  if (currentUser.paymentFee !== null && currentUser.paymentFee !== undefined) {
    return currentUser.paymentFee * 100;
  }

  const maxStartTime = Math.max(
    ...shifts.map(({ startTime }) => moment(startTime, MILITARY_FORMAT))
  );
  const fee = getEstimatedFee(maxStartTime, 0, isAmericanExpress(payment));

  return (totalEventRate({ shifts }) * fee) / 100;
};

export const totalEventRate = (event) => {
  return sum(event.shifts.map(shiftRate));
};

/**
 * This method fixes the async CLOCK of cancelling shifts - the request of
 * cancelling shifts renders back all the same shifts including the deleted ones
 */
export const filterCancelledShifts = (event, cancelledShifts = []) => ({
  ...event,
  shifts: event.shifts.filter((shift) => !cancelledShifts.includes(shift.id)),
});

export const formattedLocation = (location) => {
  const { name, address } = location;
  return [[name, address.city].filter(Boolean).join(' / '), address.state]
    .filter(Boolean)
    .join(', ');
};

export const getTimezone = (eventLocation) => {
  const zipCode = get(eventLocation, 'address.zipCode') || get(eventLocation, 'zipCode', '');
  return zipcode_to_timezone.lookup(zipCode) || moment.tz.guess();
};

export const isEndTimeAfterNow = (endTime, tz) =>
  tz ? moment.tz(endTime, tz).isAfter(moment()) : moment(endTime).isAfter(moment());

export const getEventLocations = (event) => {
  const shifts = Array.isArray(event.shifts) ? event.shifts : Object.values(event.shifts.byId);
  return uniqBy(
    shifts.map((shift) => shift.eventLocation),
    'id'
  );
};

export const removePostIdentifiers = (event) => {
  const IGNORED_EVENT_KEYS = ['id', 'createdAt', 'statistics', 'url'];
  const IGNORED_SHIFT_KEYS = [
    'id',
    'createdAt',
    'boostedAt',
    'emergencyActionPlanId',
    'jobs',
    'startTime',
    'endTime',
  ];
  const IGNORED_REPORT_TO_CONTACT_KEYS = ['id'];
  const eventCopy = omit(event, IGNORED_EVENT_KEYS);
  eventCopy.title = `${event.title}`;

  if (Array.isArray(event.location_documents)) {
    eventCopy.location_documents = event.location_documents.map((doc) => omit(doc, ['id']));
  }
  return cloneDeepWith(eventCopy, (value, key) => {
    if (key === 'shifts' && Array.isArray(value)) {
      return value.map((shift) => ({
        ...omit(shift, IGNORED_SHIFT_KEYS),
        reportToContacts: shift.reportToContacts.map((reportToContact) =>
          omit(reportToContact, IGNORED_REPORT_TO_CONTACT_KEYS)
        ),
      }));
    }
  });
};

export const duplicateEAP = (eap) => {
  const IGNORED_KEYS = ['id', 'createdAt'];

  return cloneDeepWith(omit(eap, IGNORED_KEYS), (value, key) => {
    if (['equipment', 'contacts'].includes(key) && Array.isArray(value)) {
      return value.map((item) => omit(item, IGNORED_KEYS));
    }
  });
};

export const getPageFromStep = (step) => {
  const mapper = {
    'shifts': 'shifts',
    'locations': 'locations',
    'rate': 'rate',
    'location': 'locations',
    'location-setup': 'locations',
    'schedule/recurring': 'shifts',
    'schedule/one-time': 'shifts',
    'schedule/type': 'shifts',
    'schedule-summary': 'shifts',
    'post': 'post',
  };

  return get(mapper, `[${step}]`, 'all');
};

// Function to convert image from URL to base64
export const getBlobFromUrl = (url) => {
  return fetch(url).then((response) => response.blob());
};

export const getFirstStartDate = (shifts) => {
  return moment.min(shifts.map((shift) => moment(shift.startTime)));
};

export const isUpdatingNewLocation = (location, items) => {
  const shifts = items.filter(
    (shift) =>
      !shift.cancelled &&
      moment(shift.startTime, MILITARY_FORMAT).isValid() &&
      moment(shift.endTime, MILITARY_FORMAT).isValid() &&
      [location.id, location.idx].includes(shift.locationId)
  );

  return (
    !location.id ||
    !shifts.every((shift) => [location.id, location.idx].includes(shift.locationId)) ||
    !shifts.length ||
    !rateValid(location)
  );
};

export const getShiftsByLocationId = (shifts, locationId) => {
  if (!shifts) return [];

  return shifts.filter((shift) => shift.locationId === locationId);
};

export const upcomingShiftsByLocation = (shifts, location) => {
  if (!shifts || !location) return [];

  return shifts.filter(
    (shift) =>
      !shift.cancelled &&
      [location.idx, location.id].includes(shift.locationId) &&
      isEndTimeAfterNow(shift.endTime, getTimezone(location))
  );
};

export const shiftsByLocation = (shifts, location) => {
  if (!shifts || !location) return [];

  const locationShifts = shifts.filter(
    (shift) => !shift.cancelled && [location.idx, location.id].includes(shift.locationId)
  );
  return sortBy(locationShifts, (shift) => shift.startDate);
};

export const locationMissingErrors = ({
  authenticated,
  location,
  shifts,
  eap,
  editMode,
  profile,
}) => {
  if (!shifts.length) return {};

  const locationShifts = shiftsByLocation(shifts, location);

  const isLocationValid = authenticated && locationValid(location, profile);
  const isScheduleValid = authenticated && scheduleValid(location, locationShifts, editMode);
  const isRateValid = authenticated && rateValid(location);
  const isEAPValid = authenticated && eapValid(eap, location) && completedEAP(eap, locationShifts);

  return {
    valid: isLocationValid && isScheduleValid && isRateValid && isEAPValid,
    locationValid: isLocationValid,
    scheduleValid: isScheduleValid,
    rateValid: isRateValid,
    eapValid: isEAPValid,
  };
};

export const trackMixpanelPageView = (url, user) => {
  const INCLUDE_NAME = ['SaveAsDraftClick', 'ListJobClick'];
  const eventData = {
    id: user?.id,
    email: user?.email,
  };

  if (INCLUDE_NAME.includes(url)) {
    eventData.name = user?.name;
  }

  window.mixpanel.track(url, eventData);
};

export const stepsMapper = (step) => {
  const dict = {
    EventType: 'type',
    EventSetting: 'setting',
    EventSettingDetail: 'setting-detail',
    EventProfile: 'profile',
    LocationScreen: 'location',
    LocationsIntroScreen: 'all-locations',
    EventSchedule: 'schedule',
    ScheduleSummary: 'schedule-summary',
    EventRate: 'rate',
    Details: 'details',
    Supplies: 'supplies',
    Credentials: 'credentials',
    LocationSetup: 'location-setup',
    LocationEAP: 'eap',
    PublishOrUpdateStep: 'post',
  };

  return dict[step] || step;
};

export const checkIsMultiDayShift = (shift) => {
  const DAYS_IN_MINUTES = 24 * 60;
  if (!shift.startTime || !shift.endTime) return false;

  return moment(shift.endTime).diff(moment(shift.startTime), 'minutes') > DAYS_IN_MINUTES;
};
